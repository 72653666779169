export const slugToURL = (typeName: string, slug?: string) => {
  if (!typeName) {
    return slug;
  }
  let type = typeName?.toLowerCase();

  // Pages are root pages not /page/
  if (type === 'externallink') {
    return slug;
  }
  if (type === 'page' || type === 'listingpage' || type === 'navigationlink') {
    type = '';
  } else {
    type = `/${type}`;
  }

  return `${type}/${slug}`;
};

export default slugToURL;
