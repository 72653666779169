import React from 'react';

import { Typography } from '@components/Typography';
import { Camera01 } from '@untitled-ui/icons-react';
import clsx from 'clsx';

export interface CaptionProps {
  description: string;
  animate?: boolean;
  overlay?: boolean;
  className?: string;
}

export const Caption: React.FC<CaptionProps> = ({
  description,
  animate,
  overlay,
  className,
}) => {
  if (!description) {
    return null;
  }
  return (
    <div
      className={clsx(
        'not-prose group flex place-content-center items-center py-1 md:py-2',
        overlay &&
          'absolute bottom-2 right-2 z-30 rounded-full bg-black/60 px-2 text-sm text-white transition-all duration-500 ease-in hover:bg-white  hover:text-black md:bottom-4 md:right-4',
        className
      )}>
      <Typography
        tag="p"
        className={clsx(
          'm-0 overflow-hidden text-center text-black',
          animate &&
            'h-5 w-0 transition-all duration-500 ease-in group-hover:mr-2 group-hover:w-auto'
        )}>
        {description}
      </Typography>
      <Camera01 className="relative size-3 grow-0 md:size-6" />
    </div>
  );
};

export default Caption;
