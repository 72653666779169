import clsx from 'clsx';
import React, { ElementType } from 'react';

type TypographyTags =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'li'
  | 'span'
  | 'dt'
  | 'dd'
  | 'time';

export interface TypographyProps<T extends TypographyTags> {
  tag: T;
  size?: string;
  weight?: 'font-normal' | 'font-semibold' | 'font-bold';
  className?: string;
  children?: React.ReactNode;
}

type Props<T extends TypographyTags> = TypographyProps<T> &
  React.ComponentPropsWithoutRef<T>;

export const Typography = <T extends TypographyTags>({
  tag,
  size,
  weight,
  className,
  children,
  ...props
}: Props<T>): JSX.Element => {
  // Handling default weights based on tag
  const defaultWeight = tag.startsWith('h') ? 'font-semibold' : undefined;

  const TypographyClasses = clsx(
    'Typography',
    size,
    weight || defaultWeight,
    {
      'font-heading': tag === 'h1',
      'font-subheading': tag.startsWith('h') && tag !== 'h1',
    },
    className
  );

  const Tag = tag as ElementType;

  return (
    <Tag className={TypographyClasses} {...props}>
      {children}
    </Tag>
  );
};

export default Typography;
