// FetchDB: This is used for async calls in client components ONLY
export const FetchDB = async (
  type: 'get' | 'set' | 'remove',
  key: string,
  value?: string
) => {
  const response = await fetch('/api/favourites', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      t: type,
      k: key,
      v: value,
    },
  }).then((res) => res.json());

  return response?.data;
};
