import { FetchDB } from '@lib/Vercel/FetchDB';
import { nanoid } from 'nanoid/non-secure';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface FavouriteItem {
  productID: string;
}

interface UserStore {
  uuid: string;
  favourites: FavouriteItem[];
  setFavourite: (FavouriteItem) => void;
  removeFavourite: (FavouriteItem) => void;
  getUUID: () => string;
  resetUUID: () => void;
}

export const userStore = create(
  persist<UserStore>(
    (set, get) => ({
      uuid: '',
      // Get the current UUID
      getUUID: () => {
        const { uuid } = get();
        if (!uuid) {
          // Generate a new UUID and update the state
          const newUUID = nanoid(10);
          set({ uuid: newUUID });
          return newUUID; // Return the new UUID after setting it
        }
        return uuid; // Return the existing UUID
      },
      // Reset the UUID, this isn't really used except for development
      resetUUID: () => set({ uuid: '' }),
      // Store the list of favourites, start with an empty list
      favourites: [],
      // Add an item to the favourites list
      setFavourite: async (FavouriteItem) => {
        const { uuid, favourites } = get();

        // Get the updated favourites list
        const updatedFavourites = await updateFavourites(
          FavouriteItem,
          favourites,
          uuid
        );
        // Store in localstorage
        set({ favourites: updatedFavourites });
      },
      // remove an item from the favourites list
      removeFavourite: async (FavouriteItem) => {
        const { uuid, favourites } = get();
        // Get the updated favourites list
        const updatedFavourites = await removeFavourites(
          FavouriteItem,
          favourites,
          uuid
        );

        // Store in localstorage
        set({ favourites: updatedFavourites });
      },
    }),
    {
      name: 'vsc-user',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

const updateFavourites = async (
  productID: string,
  favourites: any,
  uuid: string
) => {
  // Doesn't already exist so add it
  if (!favourites.includes(productID)) {
    favourites.push(productID);
    await FetchDB('set', uuid, productID);
  }
  return favourites;
};

const removeFavourites = async (
  productID: string,
  favourites: any,
  uuid: string
) => {
  // Push the favourite onto the list
  await FetchDB('remove', uuid, productID);
  return favourites.filter((item) => {
    if (item !== productID) {
      return item;
    }
  });
};
