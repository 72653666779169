'use client';
import { useState } from 'react';
import { Typography } from './Typography';

export const Signup: React.FC = () => {
  const [emailSubscribe, setEmailSubscribe] = useState('');
  return (
    <>
      <label htmlFor="email" className="hidden">
        Email
      </label>
      <div className="relative mt-2 rounded border">
        <input
          type="text"
          name="email"
          id="email"
          className="w-full rounded py-4 pl-6 pr-24 sm:text-sm"
          placeholder="Email address"
          onChange={(event) => {
            setEmailSubscribe(event.target.value);
          }}
        />
        <button
          className="hover:bg-brand-whale-hover active:bg-brand-whale-pressed absolute inset-y-0 right-0 m-1 rounded bg-neutral-50 px-3 py-2"
          onClick={() => {
            window.location.replace(
              'https://subscribe.visitsunshinecoast.com/vsc-subscription?email=' +
                emailSubscribe
            );
          }}>
          <Typography tag="p" size="text-base" weight="font-bold">
            SUBMIT
          </Typography>
        </button>
      </div>
    </>
  );
};

export default Signup;
