import clsx from 'clsx';
import React from 'react';
import { ReactComponent as HorizontalLogo } from '/public/logo/visitsunshinecoast-horizontal.svg';
import { ReactComponent as StackedLogo } from '/public/logo/visitsunshinecoast-stacked.svg';

type Sizes = 'xxs' | 'xs' | 'sm' | 'base' | 'lg' | 'xl';

export interface LogoProps {
  layout: 'stacked' | 'horizontal';
  size?: Sizes;
  className?: string;
}

export const LogoList = {
  stacked: StackedLogo,
  horizontal: HorizontalLogo,
};

export const Logo: React.FC<LogoProps> = ({
  layout,
  size = 'base',
  className,
}) => {
  const stackedSizes = {
    xxs: 'h-12 w-12',
    xs: 'h-16 w-16',
    sm: 'h-14 md:h-16 w-14 md:w-16',
    base: 'size-14 md:size-20',
    lg: 'h-32 md:h-48 w-32 md:w-48',
    xl: 'h-56 w-56',
  };

  const horizontalSizes = {
    xxs: 'h-4',
    xs: 'h-6 w-32',
    sm: 'h-8',
    base: 'h-10 w-auto',
    lg: 'h-9 md:h-12',
    xl: 'h-16',
  };

  const LogoClasses = clsx(
    'Image relative inline-block text-left',
    layout === 'stacked' ? stackedSizes[size] : horizontalSizes[size],
    className
  );

  const LogoSvg = LogoList[layout];

  return (
    <span className={LogoClasses}>
      <LogoSvg className="h-full" aria-label={'Visit Sunshine Coast'} />
    </span>
  );
};

export default Logo;
