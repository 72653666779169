'use client';
import { sendGTMEvent } from '@next/third-parties/google';
import { useEffect } from 'react';
import aa from 'search-insights';

import { userStore } from 'store/UserStore';

export const UUID: React.FC = () => {
  const { getUUID } = userStore();

  const uuid = getUUID();

  useEffect(() => {
    sendGTMEvent({ userId: uuid, event: 'userIDSet' });
    aa('init', {
      appId: 'GN3FTQV485',
      apiKey: process.env.NEXT_PUBLIC_ALGOLIA_VSC_KEY,
    });
    aa('setUserToken', uuid.toString());
  }, [uuid]);

  return <></>;
};

export default UUID;
